import {Image} from "antd";
import GalleryEntry from "./GalleryEntry/GalleryEntry";
import Navbar from "../Common/Navbar/Navbar";
import logo from "../Common/Icons/logo.png";
import company_name from "../Common/Icons/acs_letters.png";
import Footer from "../Common/Footer/Footer";
import "./Gallery.css";

function Gallery() {

    const names = ["Sample text","Sample text","Sample text","Sample text","Sample text","Sample text"];

    return (
        <div className="gallery"><Navbar logo={logo} company_name={company_name}
                     links={[{href: "/about", text: "O nas"}, {
                         href: "/contact",
                         text: "Kontakt"
                     }]}/>
            <Image.PreviewGroup
                preview={{
                    countRender: (current) => names[current -1]
                }}
            >
                <div className="gallery-container container">
                    <div className="row d-flex justify-content-center align-content-center">
                        <GalleryEntry image={"https://htmlcolorcodes.com/assets/images/colors/gray-color-solid-background-1920x1080.png"}
                                      title={"Sample text"} description={"This is sample description"}/>
                        <GalleryEntry image={"https://htmlcolorcodes.com/assets/images/colors/gray-color-solid-background-1920x1080.png"}
                                      title={"Sample text"} description={"This is sample description"}/>
                        <GalleryEntry image={"https://htmlcolorcodes.com/assets/images/colors/gray-color-solid-background-1920x1080.png"}
                                      title={"Sample text"} description={"This is sample description"}/>

                    </div>
                    <div className="row d-flex justify-content-center align-content-center">
                        <GalleryEntry image={"https://htmlcolorcodes.com/assets/images/colors/gray-color-solid-background-1920x1080.png"}
                                      title={"Sample text"} description={"This is sample description"}/>
                        <GalleryEntry image={"https://htmlcolorcodes.com/assets/images/colors/gray-color-solid-background-1920x1080.png"}
                                      title={"Sample text"} description={"This is sample description"}/>
                        <GalleryEntry image={"https://htmlcolorcodes.com/assets/images/colors/gray-color-solid-background-1920x1080.png"}
                                      title={"Sample text"} description={"This is sample description"}/>

                    </div>
                </div>
            </Image.PreviewGroup>
            <Footer phone={"662 939 960"} postal={"33-207 Radgoszcz"} address={"Jagiellońska 42"}
                    email={"zbyszekpikul@wp.pl"} logo={logo}/>

        </div>
    );
}

export default Gallery;