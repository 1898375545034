

function Card({children}) {

    return (
        <div
             className="card col-md col-lg col-xl d-flex flex-column align-content-center justify-content-center gy-3 text-center card gx-3">
            <div className="align-content-center flex-grow-1">
                <p>
                    {children}
                </p>
            </div>
        </div>
    );
}

export default Card;