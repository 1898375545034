import Navbar from "../Common/Navbar/Navbar.js";
import Footer from "../Common/Footer/Footer.js";
import Card from "../Common/Card/Card.js";
import './Styles/Contact.css';
import logo from "../Common/Icons/logo.png";
import company_name from "../Common/Icons/acs_letters.png";
import whatsapp_icon from "./Icons/whatsapp.svg";
import time_icon from "./Icons/Time.svg";
import contact_icon from "./Icons/Contact.svg";
import address_icon from "./Icons/Address.svg";
import rate_icon from "./Icons/Rate.svg";
import {QRCode} from "antd";
import phone_ico from "../Common/Footer/Icons/phone.svg";
import email_ico from "../Common/Footer/Icons/email.svg";

function Contact({email, phone, address, postalCode, whatsapp}) {
    return (<div>
        <Navbar logo={logo} company_name={company_name}
                links={[{href: "/about", text: "O nas"}, {
                    href: "/contact", text: "Kontakt"
                }]}/>
        <div className="contact-banner">
            <h1>Skontaktuj się z nami</h1>
        </div>
        <div className="d-flex flex-wrap flex-row justify-content-between contact">
            <div className="container contact-cards">
                <div className="row d-flex justify-content-center">
                    <Card>
                        <img className="contact-icon" src={time_icon}/>
                        <p>Poniedziałek - Niedziela: całodobowo</p>
                    </Card>
                    <Card>
                        <img className="contact-icon" src={contact_icon}/>
                        <div className="d-flex align-content-start flex-column">
                            <div className="d-flex align-content-start">
                                <img className="icon" src={phone_ico} alt=""/>
                                <a href={`tel:${phone}`}>{phone}</a>
                            </div>
                            <div>
                                <img className="icon" src={email_ico} alt=""/>
                                <a href={`mailto:${email}`}>{email}</a>
                            </div>
                        </div>
                    </Card>
                </div>
                <div className="row d-flex justify-content-center">
                    <Card>
                        <img className="contact-icon" src={address_icon} alt=""/>
                        <h2>Adres</h2>
                        <p>Jagiellońska 42</p>
                        <p>33-207 Radgoszcz</p>
                    </Card>
                    <Card>
                        <h2>Zadowolony z naszych usług?</h2>
                        <img className="contact-icon" src={rate_icon} alt=""/>
                        <p>Podziel się opinią na naszym profilu Google</p>
                        <br/>
                        <a href="https://www.google.com/search?sca_esv=dcc1d935a3011144&rlz=1C1GCEU_plPL829PL829&tbm=lcl&sxsrf=ACQVn0-hy2HiRBj-svsajLsvrSY-MSG2Bw:1714579551815&q=Air%20Conditioning%20Service%20Zbigniew%20Pikul%20Opinie&rflfq=1&num=20&stick=H4sIAAAAAAAAAONgkxI2trA0sTC2MLK0MDUyMTA3NjWx2MDI-IpRzzGzSME5Py8lsyQzPy8zL10hOLWoLDM5VSEqKTM9LzO1XCEgM7s0R8G_IBPIW8RKogYA2MNkV3wAAAA&rldimm=3894838298524073548&hl=pl-PL&sa=X&ved=0CAYQ5foLahcKEwj48srE6uyFAxUAAAAAHQAAAAAQBQ#lkt=LocalPoiReviews&arid=ChZDSUhNMG9nS0VJQ0FnSUNGMGFlVEN3EAE"
                           target="_blank">Oceń nas</a>
                    </Card>
                </div>
            </div>
            <div className="map">
                <iframe title="Map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d81720.97472781106!2d21.019594389770983!3d50.202634461058594!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa66a5e862b210e8d%3A0x360d3ecbf91d3e4c!2sAir%20Conditioning%20Service%20Zbigniew%20Pikul!5e0!3m2!1spl!2spl!4v1714489507731!5m2!1spl!2spl"
                        style={{border: 0, width: "100%", height: "100%"}} allowFullScreen="" loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
        <Footer email={email} address={address} phone={phone} postal={postalCode} logo={logo}/>
    </div>)
}

export default Contact;