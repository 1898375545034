import email_ico from './Icons/email.svg';
import phone_ico from './Icons/phone.svg';
import './Styles/Footer.css';

function Footer({address, postal, phone, email, logo}) {
    return (
        <div className="w-100 d-flex flex-row flex-wrap footer align-content-between justify-content-center">
            <div className="p-3 flex-fill">
                <img className="logo" src={logo} alt=""/>
            </div>
            <div className="p-3 flex-fill">
                <h3>Kontakt</h3>
                <div>{address}</div>
                <div>{postal}</div>
                <img className="icon" src={phone_ico} alt=""/><a href={`tel:${phone}`}>{phone}</a>
                <br/>
                <img className="icon" src={email_ico} alt=""/><a href={`mailto:${email}`}>{email}</a>
            </div>
            <div className="p-3 flex-fill">
                <h3>Godziny otwarcia</h3>
                <div>Poniedziałek - Niedziela: całodobowo</div>
            </div>
        </div>
    )
}

export default Footer;