import './Styles/AboutUs.css'
import exp from './Icons/exp.svg'
import auth from './Icons/auth.svg'
import individual from './Icons/individual.svg'
import pro from './Icons/professional.svg'
import ExpandableCard from "../../Common/ExpandableCard/ExpandableCard";

function AboutUs() {
    return (
        <div className="container about-us">
            <h2>Dlaczego my?</h2>
            <div className="row d-flex justify-content-center align-content-center partners-row">
                <ExpandableCard image={exp} title={"Doświadczenie"}>
                    Z ponad 30-letnim doświadczeniem w branży, nasza firma jest solidnym partnerem, na którego
                    zawsze
                    możesz liczyć. Właściciel i nasz zespół posiadają nie tylko wiedzę, ale także praktyczne
                    umiejętności, które gwarantują profesjonalne i skuteczne rozwiązania dla naszych klientów.
                </ExpandableCard>
                <ExpandableCard image={auth} title={"Autoryzacje znanych marek"}>
                    Jesteśmy dumnymi partnerami autoryzowanymi przez Saunier Duval,
                    Vaillant, LG i Daikin. Dzięki temu możemy oferować najwyższej jakości urządzenia oraz zapewnić
                    kompleksowe wsparcie techniczne i serwisowe, co przekłada się na długotrwałą i niezawodną
                    eksploatację.
                </ExpandableCard>
                <ExpandableCard image={individual} title={"Indywidualne podejście"}>
                    Niezależnie od tego, czy potrzebujesz instalacji,
                    klimatyzacji, pompy ciepła czy usług hydraulicznych, nasz zespół zadba o każdy szczegół, abyś,
                    otrzymał rozwiązanie idealnie dopasowane do Twoich potrzeb i oczekiwań
                </ExpandableCard>
                <ExpandableCard image={pro} title={"Profesjonalizm"}>
                    Naszą firmę cechuje profesjonalne podejście do każdego zadania oraz
                    rzetelność w działaniu. Dbamy o terminowość, jakość wykonania oraz satysfakcję naszych klientów
                    na każdym etapie współpracy.
                </ExpandableCard>
            </div>
        </div>
    )
}

export default AboutUs;