import ExpandableCard from "../../Common/ExpandableCard/ExpandableCard";
import CardGrid from "./CardGrid";
import wrench from './Icons/wrench.svg';
import snowflake from './Icons/snowflake.svg';
import wind from './Icons/wind.svg';
import ac from './Icons/ac.svg';
import pipe from './Icons/pipe.svg';
import gas from './Icons/gas.svg';
import furnace from './Icons/furnace.svg';
import factory from './Icons/factory.svg';
import './Styles/Services.css';

function Services() {
    return (
        <div className="d-flex flex-column align-items-center justify-content-center services">
            <h2>Nasze usługi</h2>
            <CardGrid>
                <ExpandableCard image={snowflake} title="Montaż klimatyzacji">
                    Nasza firma oferuje profesjonalny montaż klimatyzacji dla domów, biur oraz obiektów komercyjnych.
                    Dzięki naszemu doświadczeniu i wysoko wykwalifikowanemu zespołowi zapewniamy perfekcyjne
                    dostosowanie systemu klimatyzacji do Twoich potrzeb, zapewniając komfort termiczny przez cały rok.
                </ExpandableCard>
                <ExpandableCard image={furnace} title="Montaż kotłów gazowych">
                    Zaufaj nam, jeśli potrzebujesz montażu nowoczesnych i wydajnych kotłów gazowych. Jako autoryzowani
                    partnerzy Vaillant i Saunier Duval, nasza firma oferuje kompleksową instalację tych renomowanych
                    systemów grzewczych. Nasze usługi obejmują konfigurację oraz testowanie systemów grzewczych, aby
                    zapewnić Ci niezawodne i ekonomiczne rozwiązania do ogrzewania Twojego domu lub obiektu.
                </ExpandableCard>
                <ExpandableCard image={wrench} title="Serwis klimatyzacji">
                    Nasz serwis klimatyzacji to gwarancja długotrwałej i efektywnej pracy Twojego systemu. Nasi
                    wyspecjalizowani technicy dokonują kompleksowej diagnozy, przeglądu oraz konserwacji, aby zapewnić
                    optymalną wydajność, czystość oraz bezpieczeństwo działania Twojej klimatyzacji przez cały rok.
                </ExpandableCard>
                <ExpandableCard image={factory} title="Kompleksowa obsługa inwestycji">
                    Nasz doświadczony zespół zapewni Ci wsparcie na każdym etapie, począwszy od konsultacji
                    i projektowania, aż po montaż i serwisowanie urządzeń. Dzięki temu masz pewność, że Twoja inwestycja
                    zostanie zrealizowana zgodnie z oczekiwaniami i najwyższymi standardami.
                </ExpandableCard>
                <ExpandableCard image={wind} title="Montaż rekuperacji">
                    Montaż rekuperacji to kluczowy krok w zapewnieniu efektywnej wentylacji i odzysku ciepła w budynku.
                    Nasz zespół specjalistów zajmie się kompleksowym montażem systemów rekuperacji, zapewniając Ci
                    świeże i zdrowe powietrze oraz minimalne straty energii.
                </ExpandableCard>
                <ExpandableCard image={pipe} title="Hydraulika">
                    Specjalizujemy się w kompleksowych usługach hydrauliki, obejmujących instalacje wodno-kanalizacyjne,
                    grzewcze oraz sanitarno-techniczne. Dzięki naszemu doświadczeniu i wysokiej jakości materiałów
                    zapewniamy solidność i niezawodność naszych instalacji, bez względu na ich złożoność.
                </ExpandableCard>
                <ExpandableCard image={gas} title="Instalacje gazowe">
                    Nasza firma oferuje profesjonalne usługi związane z instalacjami gazowymi, obejmujące projektowanie,
                    montaż oraz serwisowanie systemów gazowych. Jako specjaliści z odpowiednimi uprawnieniami,
                    gwarantujemy bezpieczeństwo, zgodność z przepisami oraz optymalną wydajność instalacji gazowych.
                </ExpandableCard>
                <ExpandableCard image={ac} title="Montaż pomp ciepła">
                    Montaż pomp ciepła to doskonałe rozwiązanie dla osób poszukujących efektywnych i ekologicznych
                    systemów grzewczych. Jako autoryzowani partnerzy LG, Saunier Duval i Vaillant, nasza firma oferuje
                    profesjonalny montaż tych renomowanych pomp ciepła. Dzięki naszemu doświadczeniu i wyszkolonemu
                    personelowi zapewniamy Ci instalację, która jest nie tylko energooszczędna, ale także doskonale
                    dopasowana do Twoich potrzeb, co przekłada się na oszczędność energii i komfort termiczny w Twoim
                    domu lub obiekcie.
                </ExpandableCard>
            </CardGrid>
        </div>
    );
}

export default Services;