import Card from "../../Common/Card/Card";
import {Image} from "antd";
import './GalleryEntry.css';

function GalleryEntry({ image, description, title }) {
    return (
        <Card>
            <Image
                src={image}
            />
            <h2>{title}</h2>
            <p>{description}</p>
        </Card>
    )
}

export default GalleryEntry;