import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import './Components/Common/Styles/Colors.css';
import reportWebVitals from './reportWebVitals';
import {RouterProvider} from "react-router-dom";
import {createBrowserRouter} from "react-router-dom";
import Index_site from "./Components/Index/Index_site.js";
import Contact from "./Components/Contact/Contact.js";
import About from "./Components/About/About";
import Gallery from "./Components/Gallery/Gallery";

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter(
    [{
        path: "/",
        children: [
            {
                path: "",
                element: <Index_site/>
            },
            {
                path: "/contact",
                element: <Contact phone={"662 939 960"} whatsapp={"https://w.me/1344234"} postalCode={"33-207 Radgoszcz"} address={"Jagiellońska 42"} email={"zbyszekpikul@wp.pl"}/>
            },
            {
                path: "/about",
                element: <About/>
            },
            {
                path: "/images",
                element: <Gallery/>
            }
        ]
    }],
);


root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
