import 'bootstrap/dist/css/bootstrap.css';

function getChildrenRow(children) {
  let childrenRow = [];
  for(let i = 0; i <= children.length; i+=4) {
    let childrenCol = [];
    for (let j = i; j < i + 4; j++) {
      childrenCol.push(
          //<div className="col-md-6 col-lg-3">
            //<div>
          children[j]
          //</div>
          //</div>
      );
    }
    childrenRow.push(
        //<div className="row w-100 align-items-center p-3 gy-3">
        <div className= "row d-flex justify-content-center align-content-center partners-row">
              {childrenCol}
        </div>
    );
    }
    return childrenRow;
}
function CardGrid({ children }) {
  return (
    <div className="container">
      {getChildrenRow(children)}
    </div>
  );
}

export default CardGrid;