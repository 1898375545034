import './Styles/Partners.css'

function Partners({partners}) {
    let partnersList = partners.map((partner) => {
        return (
            <div className="col-md d-flex justify-content-center partner-container gy-3">
                <a href={partner.href}>
                    <img className="partner-image" src={partner.img} alt={partner.name}/>
                </a>
            </div>
        )
    })
    return (
        <div className="container partners">
            <div className="row">
                <div className="col-md-12">
                    <h2 className="text-center">Nasi partnerzy</h2>
                </div>
            </div>
            <div className="row d-flex justify-content-center align-content-center partners-row">
                {partnersList}
            </div>
        </div>
    )
}

export default Partners