import './Styles/Navbar.css';

function Navbar({logo, company_name, links}) {
    return (
        <div>
            <div
                className="w-100 d-none d-lg-flex d-xl-flex flex-row footer align-content-center justify-content-between">
            <a href="/" className="d-flex align-content-center justify-content-center">
                <div className="h-100 d-grid"><img className="logo-top align-self-center" src={logo} alt=""/></div>
                <img className="company-name" src={company_name} alt=""/>
            </a>
                <span className="d-flex flex-row flex-wrap align-content-center m-5">
            {links.map((link) => (
                <a className="nav-link navigation" href={link.href}>{link.text}</a>
            ))}
          </span>
            </div>
            <nav className="navbar d-lg-none d-xl-none navbar-dark nav-collapse d-flex">
                <div className="container-fluid">
                    <a className="navbar-brand" href="/">
                        <img src={logo} className="logo-top" alt=""/>
                        <img src={company_name} className="company-name" alt=""/>
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarContent">
                        <span className="navbar-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" >
                                <path fill="#ffffff" d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"/>
                            </svg>
                        </span>
                    </button>
                </div>
            </nav>
            <div className="collapse navbar-content" id="navbarContent">
                <div
                className="d-flex flex-column flex-wrap align-content-center justify-content-center list-group">
                {links.map((link) => (
                    <a className="nav-link navigation list-group-item-action text-center" href={link.href}>{link.text}</a>
                ))}
                </div>
            </div>
        </div>
    );
}

export default Navbar;