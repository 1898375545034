import './Styles/ImageSection.css';
function ImageSection({ image,position, children }) {
    if(position !== "left" && position !== "right") throw new Error("Position prop must be either 'left' or 'right'");

    if(position === "left") {
        return (
            <div className="image-section image-section-left">
                <div className="image-section-children" style={{paddingRight:"5vmax"}}>
                    {children}
                </div>
                <img className="image-section-image" src={image} alt=""/>
            </div>
        )
    } else {
        return (
            <div className="image-section image-section-right">
                <img className="image-section-image" src={image} alt=""/>
                <div className="image-section-children" style={{paddingLeft:"5vmax"}}>
                    {children}
                </div>
            </div>
        )
    }
}

export default ImageSection;