import './Styles/Banner.css'

function Banner({image_desktop, image_mobile}) {
    return (
        <div>
            <div className="banner position-relative d-none d-md-block d-xl-block d-lg-block">
                <img src={image_desktop} alt={"Banner"} style={{width: "100%"}}/>
            </div>
            <div className="banner position-relative d-md-none d-xl-none d-lg-none">
                <img src={image_mobile} alt={"Banner"} style={{width: "100vw"}}/>
            </div>
        </div>
    )
}


export default Banner;
