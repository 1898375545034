import Navbar from "../Common/Navbar/Navbar";
import logo from "../Common/Icons/logo.png";
import company_name from "../Common/Icons/acs_letters.png";
import Banner from "./Banner/Banner";
import banner_img_desktop from "./Banner/Images/pic1_downsampled_desktop.jpg";
import banner_img_mobile from "./Banner/Images/pic1_downsampled_mobile.jpg";
import Services from "./Services/Services";
import Partners from "./Partners/Partners";
import daikin from "../Common/Icons/daikin-logo.png";
import AboutUs from "./AboutUs/AboutUs";
import Footer from "../Common/Footer/Footer";

function Index_site(){
    return(
        <div>
            <Navbar logo={logo} company_name={company_name} links={[{href:"/about",text:"O nas"},{href:"/contact",text:"Kontakt"}]}/>
            <Banner image_desktop={banner_img_desktop} image_mobile={banner_img_mobile}/>
            <Services/>
            <Partners partners={
                [
                    {href:"https://www.vaillant.pl",img:"//www.vaillant.pl/media-master/global-media/layout/logos/old/vaillant-logo-272x72-1888261.png",name:"Vaillant"},
                    {href:"https://www.saunierduval.pl",img:"//www.saunierduval.pl/media-master/global-media/layout/logos/sdbg-logos-2022/sd-pl-pl-2379267.png",name: "Saunier Duval"},
                    {href:"https://www.lg.com",img:"https://www.lg.com/lg5-common-gp/images/common/header/logo-b2b.jpg",name:"LG"},
                    {href:"https://www.daikin.pl",img:daikin,name:"Daikin"}
                ]}/>
            <AboutUs/>
            <Footer phone={"662 939 960"} postal={"33-207 Radgoszcz"} address={"Jagiellońska 42"} email={"zbyszekpikul@wp.pl"} logo={logo}/>
        </div>
    )
}

export default Index_site;