import {useRef} from "react";
import "./Styles/Card.css";

function ExpandableCard({image, title, children}) {

    let card = useRef(null);
    let desc = useRef(null);
    let handleCollapse = (e) => {

        let collapse = desc.current;
        collapse.style.height = 0;
        card.current.style.borderWidth = 0;
        if (collapse.classList.contains("closed")) {
            collapse.classList.remove("closed");
	    collapse.classList.add("open");
            collapse.style.height = collapse.scrollHeight + "px";
            card.current.style.borderWidth = "0.25vmax";
        } else {
            collapse.classList.add("closed");
	    collapse.classList.remove("open");
            collapse.style.height = "0px";
            card.current.style.borderWidth = 0;
        }
    }


    return (
        <div ref={card}
             className="col-md-4 col-lg col-xl d-flex flex-column align-content-start justify-content-center gy-3 text-center card gx-3"
             onClick={handleCollapse}>
            <div className="align-content-center flex-grow-1">
                <div className="aligner"><img className="card-icon" src={image} alt=""/></div>
                <h3 className="text-center card-text">{title}</h3>
            </div>
            <p ref={desc} className={"expandable closed"}>
                {children}
            </p>
        </div>
    );
}

export default ExpandableCard;
