import Navbar from "../Common/Navbar/Navbar";
import logo from "../Common/Icons/logo.png";
import company_name from "../Common/Icons/acs_letters.png";
import Footer from "../Common/Footer/Footer";
import ImageSection from "./ImageSection/ImageSection";
import './Styles/About.css';
import pic1 from './Images/pic1.jpg';
import pic2 from './Images/pic2.jpg';
import pic4 from './Images/pic4.jpg';
import pic3 from './Images/pic3.jpeg';
import pic5 from './Images/pic5.jpeg';
function About() {
    return (
        <div>
            <Navbar logo={logo} company_name={company_name}
                    links={[{href: "/about", text: "O nas"}, {
                        href: "/contact",
                        text: "Kontakt"
                    }]}/>
            <div className="about-page">
                <ImageSection position={"left"}
                              image={pic3}>
                    <h1>O nas</h1>
                    <p>
                        Witaj w Air Conditioning Service, Twoim partnerze w zakresie profesjonalnych usług montażu
                        klimatyzacji, pomp
                        ciepła i hydrauliki. Jesteśmy dynamiczną firmą, która od lat świadczy kompleksowe usługi dla
                        klientów z sektora prywatnego i biznesowego. Nasz zespół doświadczonych fachowców oraz
                        pasjonatów
                        branży gwarantuje najwyższą jakość świadczonych usług oraz pełne zadowolenie klientów.
                    </p>
                </ImageSection>
                <ImageSection position={"right"}
                              image={pic1}>
                    <h1>Co oferujemy?</h1>
                    <p>
                        W naszej ofercie znajdziesz najwyższej jakości klimatyzatory, pompy ciepła oraz systemy
                        grzewcze.
                        Oferujemy również profesjonalne doradztwo w zakresie doboru odpowiedniego sprzętu oraz jego
                        montażu.
                        Współpracujemy z najlepszymi producentami, dzięki czemu możemy zagwarantować najwyższą jakość
                        oferowanych produktów.
                    </p>
                </ImageSection>
                <ImageSection position={"left"}
                              image={pic2}>
                    <h1>Nasza misja</h1>

                    <p>
                        Naszą misją jest dostarczanie innowacyjnych i efektywnych rozwiązań w zakresie klimatyzacji,
                        pomp
                        ciepła i hydrauliki, zapewniając naszym klientom komfortowe warunki w ich przestrzeniach
                        mieszkalnych i biznesowych. Dążymy do zrównoważonego rozwoju oraz dbamy o ekologiczne aspekty
                        naszej działalności, oferując rozwiązania przyjazne dla środowiska.
                    </p>
                </ImageSection>
                <ImageSection position={"right"}
                              image={pic5}>
                    <h1>Nasza wizja</h1>
                    <p>
                        Naszą wizją jest stałe doskonalenie naszych usług oraz poszerzanie oferty o nowe produkty i
                        technologie, które pozwolą nam sprostać oczekiwaniom naszych klientów. Dążymy do ugruntowania
                        pozycji na rynku oraz do budowania długotrwałych relacji z naszymi klientami.
                    </p>
                </ImageSection>
                <ImageSection position={"left"}
                              image={pic4}>
                    <h1>Co nas wyróżnia?</h1>
                    <ol>
                        <div>
                            <h4>Doświadczenie</h4>
                            <p>
                                Nasz zespół składa się z wysoko wykwalifikowanych specjalistów,
                                którzy posiadają bogate doświadczenie w branży. Dzięki temu możemy świadczyć usługi na
                                najwyższym poziomie.
                            </p>
                        </div>

                        <div>
                            <h4>Innowacyjność</h4>
                            <p> Śledzimy najnowsze trendy i technologie w dziedzinie klimatyzacji
                                i
                                hydrauliki, co pozwala nam proponować naszym klientom nowoczesne i energooszczędne
                                rozwiązania.
                            </p>
                        </div>

                        <div>
                            <h4>Wsparcie i doradztwo</h4>
                            <p>
                                Nie kończymy naszej działalności na etapie montażu. Oferujemy naszym
                                klientom kompleksowe wsparcie techniczne oraz doradztwo w zakresie eksploatacji i
                                konserwacji instalacji.
                            </p>
                        </div>
                    </ol>
                </ImageSection>
            </div>
            <Footer phone={"662 939 960"} postal={"33-207 Radgoszcz"} address={"Jagiellońska 42"}
                    email={"zbyszekpikul@wp.pl"} logo={logo}/>
        </div>
    )
}

export default About;